<template>
  <v-dialog v-model="active" width="600">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">Select Capstone Carrel</v-btn>
    </template>
    <v-card>
      <v-toolbar style="margin-bottom:1em">
        <v-toolbar-title>Select Capstone Carrel</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="active = false">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <carrel-layout :selected-carrel="selectedCarrel" :carrel-status="carrelStatus" @click="selectCarrel"></carrel-layout>
        <v-toolbar>
          <span style="margin-right:15px">Color Key:</span>
          <div>
            <div style="background-color:#4CAF50;display:inline-block;width:10px;height:10px;margin-right:5px"></div> Selected
          </div>
          <v-spacer></v-spacer>
          <div>
            <div style="background-color:#FFA000;display:inline-block;width:10px;height:10px;margin-right:5px"></div> Partially Filled (Shared)
          </div>
          <v-spacer></v-spacer>
          <div>
            <div style="background-color:#FF5252;display:inline-block;width:10px;height:10px;margin-right:5px"></div> Unavailable
          </div>
        </v-toolbar>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Cancel</v-btn>
        <v-btn :disabled="!selectedCarrel" color="success" text @click="save">Reserve Carrel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref, onMounted } from '@vue/composition-api'

export default {
  props: {
    term: {
      type: String,
      required: true
    }
  },
  components: {
    CarrelLayout: () => import('./carrelLayout')
  },
  setup (props, { root, emit }) {
    const service = root.$feathers.service('library/carrel')
    const termService = root.$feathers.service('library/carrel-term')
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const active = ref(false)
    const selectedCarrel = ref(null)
    const carrels = ref([])
    const carrelStatus = ref({})
    const search = ref('')

    onMounted(async () => {
      const query = { term: props.term, $limit: 0 }
      const { total } = await service.find({ query })
      query.$limit = 50
      for (let i = 0; i < total; i += 50) {
        query.$skip = i
        const { data } = await service.find({ query })
        data.forEach((row) => {
          carrels.value.push(row)
          const { carrel, slots } = row
          let spotsTaken = 0
          let spotsAvailable = 0
          slots.forEach(({ available }) => {
            if (available) spotsAvailable++
            else spotsTaken++
          })
          let status = 'full'
          if (spotsAvailable === 0) status = 'full'
          else if (spotsTaken === 0) status = 'empty'
          else status = 'partial'
          carrelStatus.value[carrel] = status
        })
      }
    })

    async function save () {
      const { data } = await service.find({ query: { term: props.term, carrel: selectedCarrel.value } })
      if (data.length === 1) {
        const { _id, slots } = data[0]
        const obj = { available: false, name: user.value.name, directoryId: user.value.directoryId }
        let slotTaken = -1
        for (let i = 0; i < slots.length; i++) {
          if (slots[i].available === true) {
            slotTaken = i
            break
          }
        }
        if (slotTaken >= 0) {
          try {
            await service.patch(_id, { ['slots.' + slotTaken]: obj })
            active.value = false
            emit('selected', selectedCarrel.value)
            sendNotification()
          } catch (e) {
            alert('Error reserving slot: ' + e)
          }
        }
      }
    }

    async function sendNotification () {
      const { data } = await termService.find({ query: { term: props.term } })
      if (data.length > 0) {
        const { email } = data[0]
        if (email) {
          // There is an email set up for the carrel term, so send a notification to that email that this carrel was just reserved. It will include a count of how many slots are still available, so we need to run an aggregation to collect that
          const aggregate = [
            { $match: { term: props.term } },
            { $unwind: '$slots' },
            { $group: { _id: '$slots.available', count: { $sum: 1 } } }
          ]
          const { data: aggData } = await service.find({ query: { aggregate } })
          let total = 0
          let available = 0
          for (const { _id, count } of aggData) {
            total += count
            if (_id) available = count
          }
          await root.$feathers.service('system/email').create({ from: 'report-email@covenant.edu', to: email, subject: 'Capstone Carrel Reserved', html: 'Capstone Carrel ' + selectedCarrel.value + ' was just reserved by ' + user.value.name + '. This leaves ' + available + ' out of ' + total + ' slots.<br/><br/>To access the Capstone Carrel admin, visit <a href="https://portal.covenant.edu/library/capstone-carrel/admin">https://portal.covenant.edu/library/capstone-carrel/admin</a>' })
        }
      }
    }

    function selectCarrel (newCarrel) {
      if (carrelStatus.value[newCarrel] === 'full') return
      selectedCarrel.value = newCarrel
    }

    return {
      active,
      selectedCarrel,
      carrels,
      carrelStatus,
      search,
      save,
      selectCarrel
    }
  }
}
</script>
